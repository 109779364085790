const ConstsDict = {
    currentSemester: 1,
    semesterFirstDay: {
        year: "2024",
        month: "11",
        day: "03"
    },
    semesterLastDay: {
        year: "2025",
        month: "02",
        day: "02"
    }
};

export default ConstsDict;
